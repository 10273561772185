exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-categories-[id]-[id]-tsx": () => import("./../../../src/pages/categories/[id]/[id].tsx" /* webpackChunkName: "component---src-pages-categories-[id]-[id]-tsx" */),
  "component---src-pages-categories-[id]-index-tsx": () => import("./../../../src/pages/categories/[id]/index.tsx" /* webpackChunkName: "component---src-pages-categories-[id]-index-tsx" */),
  "component---src-pages-categories-index-tsx": () => import("./../../../src/pages/categories/index.tsx" /* webpackChunkName: "component---src-pages-categories-index-tsx" */),
  "component---src-pages-category-management-index-tsx": () => import("./../../../src/pages/category-management/index.tsx" /* webpackChunkName: "component---src-pages-category-management-index-tsx" */),
  "component---src-pages-checkout-cancel-index-tsx": () => import("./../../../src/pages/checkout/cancel/index.tsx" /* webpackChunkName: "component---src-pages-checkout-cancel-index-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-checkout-success-index-tsx": () => import("./../../../src/pages/checkout/success/index.tsx" /* webpackChunkName: "component---src-pages-checkout-success-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policy-index-tsx": () => import("./../../../src/pages/policy/index.tsx" /* webpackChunkName: "component---src-pages-policy-index-tsx" */),
  "component---src-pages-product-management-index-tsx": () => import("./../../../src/pages/product-management/index.tsx" /* webpackChunkName: "component---src-pages-product-management-index-tsx" */),
  "component---src-pages-transaction-list-index-tsx": () => import("./../../../src/pages/transaction-list/index.tsx" /* webpackChunkName: "component---src-pages-transaction-list-index-tsx" */)
}

