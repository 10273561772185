import React, { createContext, useState, useEffect } from 'react'
import {CartItem} from "../types/cartItem";
import Cookies from 'js-cookie';

interface CartContextType {
    cart: CartItem[];
    cartOpen: boolean;
    toggleCart: () => void;
    addToCart: (item: CartItem) => void;
    removeFromCart: (item: CartItem) => void;
    clearCart: () => void;
    forceCart: (state: boolean) => void;
    getCart: () => void;
}

const defaultContext: CartContextType = {
    cart: [],
    cartOpen: false,
    getCart: () => {},
    toggleCart: () => {},
    addToCart: () => {},
    removeFromCart: () => {},
    clearCart: () => {},
    forceCart: () => {},
}

export const CartContext = createContext<CartContextType>(defaultContext)

export const CartProvider = ({ children }: any) => {
    const [cart, setCart] = useState<CartItem[]>([])
    const [cartOpen, setCartOpen] = useState<boolean>(false)

    const toggleCart = (): void => {
        setCartOpen(!cartOpen)
    }
    const isProduction: boolean = process.env.NODE_ENV === 'production';
    const cookieConfig: {expires: number, path: string, secure: boolean, domain: string | undefined} = {
        expires: 7,
        path: '/',
        secure: isProduction,
        domain: isProduction ? '.expo-gubin.pl' : undefined
    };

    const forceCart = (state: boolean) => setCartOpen(state);

    const addToCart = (item: CartItem): void => {
        const existingItemIndex = cart.findIndex((cartItem: CartItem): boolean => cartItem.variant === item.variant);

        if (existingItemIndex !== -1) {
            const updatedCart: CartItem[] = [...cart];
            updatedCart[existingItemIndex].quantity += item.quantity;
            setCart(updatedCart);
            Cookies.set('cart', JSON.stringify(updatedCart), cookieConfig);
        } else {
            setCart([...cart, item]);
            Cookies.set('cart', JSON.stringify([...cart, item]), cookieConfig);
        }
    };

    const removeFromCart = (item: CartItem): void => {
        setCart(cart.filter((cartItem: CartItem) => cartItem !== item));
        Cookies.set('cart', JSON.stringify(cart.filter((cartItem: CartItem) => cartItem !== item)), cookieConfig);
    }

    const clearCart = (): void => {
        setCart([])
        Cookies.set('cart', JSON.stringify([]), cookieConfig);
    }
    const getCart = () => {
        return Cookies.get('cart') || [];
    }

    useEffect((): void => {
        const cart: string | undefined = Cookies.get('cart');
        if (cart) {
            setCart(JSON.parse(cart))
        }
    }, [])

    return (
        <CartContext.Provider
            value={{
                cart,
                cartOpen,
                toggleCart,
                addToCart,
                removeFromCart,
                clearCart,
                forceCart,
                getCart
            }}
        >
            {children}
        </CartContext.Provider>
    )
}
